import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { find, forIn, forEach } from "lodash";
import moment from "moment";
import { groupBy, orderBy, filter, uniqBy } from "lodash";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  menuRequest: ["local_id"],
  menuSuccess: ["menu", "products"],
  menuFailure: ["error"],
  cleanMenu: [],
  refreshMenu: ["search"],
});

export const MenuTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  products: [],
  local_id: null,
  error: null,
  fetching: false,
  filter: null
});

/* ------------- Reducers ------------- */

export const request = (state, { local_id }) => {
  return {
    ...state,
    fetching: state.local_id === local_id ? false : true,
    local_id,
  };
};

export const success = (state, action) => {
  const { menu, products } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    products,
    menu,
    date: moment().format(),
  };
};

export const failure = (state, { error }) => {
  return { ...state, fetching: false, error };
};

export const cleanMenu = (state) => INITIAL_STATE;

export const refreshMenu = (state, action) => {
  const { search } = action;

  const myReg = new RegExp(search, "gi");

  return {
    ...state,
    menu: createMenu(state.products.filter((item) => item.name.match(myReg))),
    filter: search
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.MENU_REQUEST]: request,
  [Types.MENU_SUCCESS]: success,
  [Types.MENU_FAILURE]: failure,
  [Types.CLEAN_MENU]: cleanMenu,
  [Types.REFRESH_MENU]: refreshMenu,
});

/* ------------- Selectors ------------- */

export const getProductById = (state, id) => {
  let products = [];
  forIn(state.menu.menu, (categoryProducts) => {
    products = [...products, ...categoryProducts];
  });
  return find(products, ["id", parseInt(id)]);
};

export const createMenu = (productos) => {
  const featured = filter(productos, (producto) => {
    return producto.featured;
  });
  let categorias = groupBy(productos, (producto) => {
    return producto.categoriaProducto.name;
  });

  let tmpCategorias = [];
  forEach(productos, (producto) => {
    tmpCategorias.push(producto.categoriaProducto);
    //if (!producto.categoriaProducto.delete && !producto.categoriaProducto.invalidTo) {
    //  tmpCategorias.push(producto.categoriaProducto);
    //}
  });
  tmpCategorias = uniqBy(tmpCategorias, (categoria) => {
    return categoria.name;
  });
  tmpCategorias = orderBy(tmpCategorias, "order");

  let finalCategorias = {};
  if (featured.length > 0) {
    finalCategorias.featured = featured;
  }
  forEach(tmpCategorias, (categoria) => {
    finalCategorias[categoria.name] = categorias[categoria.name];
  });

  return finalCategorias;
};

export const getProductFilter = (state, search) => {
  const myReg = new RegExp(search, "gi");

  const products = state.products.filter((item) => item.name.match(myReg));

  return createMenu(products);
};
